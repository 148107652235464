@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,700,700i,900);
@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);
@import url(https://cdn.linearicons.com/free/1.0.0/svgembedder.min.js);
@import url(//fonts.googleapis.com/css?family=Open+Sans:400,600,700,800,300);
@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap);
.ant-menu-sub.ant-menu-inline{
    background: #f3f4f9 !important;
}
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content{
    font-weight: 600 !important;
  }
  .ant-menu-inline{
    border-right: none !important;
}
.ant-menu-vertical{
    border-right: none !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    /* border-left: 3px solid #A4CD38 !important; */
}
.ant-drawer-header{
    padding: 0 !important;
}
.ant-drawer-content{
    overflow: hidden !important;
}
.ant-drawer-body {
    padding: 0;
}
/* .ant-collapse-content {
    background-color: #EEF1F7 !important;
}
.ant-collapse-content > .ant-collapse-content-box{
    padding: 0;
} */

html {
  font-size: 16px; /* Set your base font size here */
}

.toggleStyles {
  width: 100%;
  padding-bottom: 5px;
  cursor: pointer;
}

.topMenuIcons {
  width: 95%;
  padding-bottom: 14%;
  margin-left: 30%;
}

.topMenuSettingIcons {
  width: 45%;
  padding-bottom: 13%;
  margin-left: 40%;
}

.downArrow {
  color: white;
  position: absolute;
  top: 43%;
  right: 0;
  margin-top: -2px;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  transition: all 0.2s ease-in-out;
}

.cardstyle {
  margin: -1% !important;
  border: none !important;
  box-shadow: 0px 0px 0px 0px #707070 !important;
  width: 13.5rem !important;
  padding: 0px !important;
}

.react-pdf__Page__canvas {
  width: 597px !important;
}

.ant-result {
  padding: 0px !important;
}

.popOver {
  padding: 0px 0px !important;
  width: 15rem !important;
}

.loginPageButton {
  width: 100% !important;
  font-size: 14px !important;
  background-color: #1648aa !important;
  border: 1px solid #e4e9f0 !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  font-weight: 600 !important;
}

.loginText {
  text-align: center;
}

.inner {
  min-width: 20rem;
  max-width: 27rem;
  margin: 0 auto;
  margin-left: 17%;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  border-radius: 20px;
  overflow: hidden;
  background-color: #f2f3f6 !important;
  position: relative;
}

.textinner {
  min-width: 25rem !important;
  max-width: 27.66rem;
  margin-top: 14%;
  margin-left: 5%;
  padding-left: 1%;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  padding: 5px !important;
}

.block {
  margin-top: '121px';
  /* padding: 12% 3.33rem 6.66rem 1.8%; */
  /* width: 100%; */
  /* align-self: center; */
  /* position: relative; */
  /* z-index: 2; */
  /* width: 100%; */
  /* min-height: 100vh;
  flex-direction: column;
  align-items: center; */
}

.logoStyles {
  width: 50%;
  padding-top: 5px;
  margin-left: -4%;
}

.newButtonForlist {
  font-size: 14px !important;
  height: 2rem !important;
  width: 5rem !important;
  background: #089ea4 !important;
  border: 0.5px solid #07888d !important;
  border-radius: 2px !important;
  color: #ffffff !important;
  opacity: 1;
  font-weight: 600 !important;
}

.listCustomButtons {
  height: 35px !important;
  width: 35px !important;
  color: #706e6b !important;
  border: 0.5px solid #dddbda !important;
  cursor: pointer;
  padding-left: 5px !important;
  padding-right: 5px !important;
  border-radius: 5px !important;
  float: right !important;
  margin-right: 5px !important;
}

.listCustomButtonsLast {
  height: 35px !important;
  width: 35px !important;
  color: #706e6b !important;
  border: 0.5px solid #dddbda !important;
  cursor: pointer;
  padding-left: 5px !important;
  padding-right: 5px !important;
  border-radius: 5px !important;
  float: right !important;
}

.listButtonsImage {
  margin-top: 6% !important;
  margin-bottom: 6px !important;
  padding-left: 2px !important;
  padding-top: 2px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.aboveHeaderButtons {
  height: 30px !important;
  width: auto !important;
  font-weight: 600 !important;
  color: #717172 !important;
  border-radius: 3px !important;
  border: 0.1px solid #dddbda !important;
  opacity: 1;
  cursor: pointer;
  margin-left: 4px;
}

.quickAddButtons {
  height: 25px !important;
  width: 30px !important;
  color: #706e6b !important;
  border: 0.5px solid #dddbda !important;
  cursor: pointer;
  padding-left: 1px !important;
  padding-right: 5px !important;
  padding-bottom: 3px !important;
  /* border-radius: 5px !important; */
  margin-top: 0.2em !important;
  float: right !important;
}
.quickAddButtons1 {
  height: 36px !important;
  width: 35px !important;
  color: #706e6b !important;
  border: 0.5px solid #dddbda !important;
  cursor: pointer;
  padding-top: 5px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  border-radius: 5px !important;
  float: right !important;
}
.onHover:hover {
  max-width: "100%";
  overflow-x: scroll;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}

.dragHandler:hover {
  cursor: move;
  background-color: #ccc;
}
.target {
  position: fixed !important;
}
.flex-spread {
  display: flex;
  float: right;
  margin-top: 6px;
}
.flex-spreadLines {
  display: flex;
  float: right;
}
.flex-spread1 {
  display: flex;
  float: right;
}
.flex-spreadTitle {
  display: flex;
  float: left;
}
.target {
  text-decoration: underline;
  cursor: pointer;
}
/* .react-tooltip-lite {
  background: #4e5355 !important;
  color: white !important;
}
.setting .react-tooltip-lite {
  background: #4e5355 !important;
} */
.arrow-content-tooltip .react-tooltip-lite {
  box-sizing: border-box;
  border: 1px solid #444444;
  border-radius: 3px !important;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2);
  background-color: #444444 !important;
  opacity: 80%;
  width: auto !important;
  display: inline-block;
  vertical-align: middle;
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  font-weight: 600 !important;
  font-family: "open sans" !important;
  color: white !important;
}
.arrow-content-tooltip .react-tooltip-lite-down-arrow {
  opacity: 80%;
  color: #444444;
  margin-left: 4px !important;
}

.arrow-content-tooltipOne .react-tooltip-lite {
  box-sizing: border-box;
  border: 1px solid #ffffff;
  border-radius: 3px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  background-color: #ffffff !important;
  width: auto !important;
  display: inline-block;
  vertical-align: middle;
  padding-left: 18px !important;
  padding-right: 26px !important;
  padding-top: 18px !important;
  padding-bottom: 18px !important;
  font-weight: 500 !important;
  font-family: "open sans" !important;
  color: #080707 !important;
}
.arrow-content-tooltipOne .react-tooltip-lite-down-arrow {
  color: #ffffff;
  margin-left: 4px !important;
  width: 20px !important;
  height: auto !important;
  box-shadow: 0px 0px 0px #00000029 !important;
}

.arrow-content-tooltipList .react-tooltip-lite {
  box-sizing: border-box;
  border: 1px solid #444444;
  border-radius: 3px !important;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2);
  background-color: #444444 !important;
  opacity: 80%;
  width: auto !important;
  display: inline-block;
  vertical-align: middle;
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  font-weight: 600 !important;
  font-family: "open sans" !important;
}
.arrow-content-tooltipList .react-tooltip-lite-down-arrow {
  opacity: 80%;
  color: #444444;
}
.arrow-content-tooltipList .react-tooltip-lite-up-arrow {
  opacity: 80%;
  color: #444444;
}
.responsiveSideMenu {
  width: 50px !important;
  background-color: '#E1E3E7';
}
.table-responsive {
  overflow-y: scroll;
  height: 560px;
}
.drawerClose {
  position: fixed;

  /* display: block; */
  top: 80px;
  /* border-radius: 50%; */
  width: 40px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  line-height: 1.5715;

  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 0px solid transparent;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  /* height: 32px; */
  padding: 4px 15px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #fff;
}

.introjs-tooltip {
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  background-color: #fff;
  min-width: 250px;
  max-width: 350px !important;
  width: -webkit-max-content;
  width: max-content;
  border-radius: 5px;
  box-shadow: 0 3px 30px rgb(33 33 33 / 30%);
  transition: opacity .1s ease-out;
}

.introjs-nextbutton {
  box-sizing: content-box;
  position: relative;
  overflow: visible;
  padding: 0.5rem 1rem;
  border: none !important;
  text-decoration: none;
  /* text-shadow: 1px 1px 0 #fff; */
  font-size: 14px;
  font-weight: 700;
  color: #FFFFFF !important;
  white-space: nowrap;
  cursor: pointer;
  outline: 0;
  background-color: #0C173A !important;
  border-radius: 0.2em;
  zoom: 1;
  display: inline;
}
.introjs-button {
  box-sizing: content-box;
  position: relative;
  overflow: visible;
  padding: 0.5rem 1rem;
  /* border: 1px solid #bdbdbd; */
  text-decoration: none;
  text-shadow:none !important;
  font-size: 14px;
  color: #424242;
  white-space: nowrap;
  cursor: pointer;
  outline: 0;
  background-color: #f4f4f4;
  border-radius: 0.2em;
  zoom: 1;
  display: inline;
}

.introjs-prevbutton {
  background: #FFFFFF !important;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2) !important;
  border-radius: 4px !important;
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 16px !important;

  color: #0C173A !important;

}

.introjs-bullets ul li a {
  border-radius: 50%;
  width: 9px !important;
  height: 9px;
  margin: 0 2px;
}

.introjs-bullets ul li a.active {
  background-color: #88B906 !important;
  border-radius: 50%;
  width: 9px !important;
  height: 9px;
  margin: 0 2px;
}

.bullets ul li a:hover {
  /* width: ; */
  background: #999;
  text-decoration: none;
  outline: 0;
}

.introjs-nextbutton {
  float: unset;
  margin-left: 10px;
}

.introjs-prevbutton {
  float: unset;
}

.introjs-tooltipbuttons {
  border-top: 1px solid #e0e0e0;
  padding: 20px;
  text-align: right;
  white-space: nowrap;
  background: #FFFFFF;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.introjs-helperLayer {
  box-shadow: rgb(33 33 33 / 80%) 0px 0px 0px 0px, rgb(33 33 33 / 50%) 0px 0px 0px 5000px !important;
  border: none !important;
  
}


/* ANTD SELECTTORS */

.ant-table-thead > tr > th {
  font: normal normal 600 13px "Inter" !important;
  text-align: left;
  letter-spacing: 0.7px;
  background: #fafafa;
  
  opacity: 1;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
  border-top: 1px solid #f0f0f0;
}

.ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 1.4;
  list-style: none;
  -webkit-font-feature-settings: "tnum", "tnum", "tnum";
          font-feature-settings: "tnum", "tnum", "tnum";
  position: relative;
  font-size: 14px;
  background: #fff;
  border-radius: 2px;
  /* color: #080707; */
  cursor: pointer;
}
.ant-table.ant-table-small {
  font-size: 12px;
}
.ant-table-body {
  padding-bottom: 25px;
}

table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  padding: 7px 5px 7px 5px;
  left: -5px;
}

.ant-layout-header {
  padding: 0 ;
  /* color: #fff; */
  /* background-color: #FFFFFF; */
}

.ant-menu-submenu-title {
  padding-left: 0px !important;
}

.ant-card-body {
  padding: 8px;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0px solid #f0f0f0;
}

.ant-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 13px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum", "tnum", "tnum";
          font-feature-settings: "tnum", "tnum", "tnum";
  position: relative;
  background: #fff;
  border-radius: 2px;
}

/* ANTD IDENTIFIERS */

#components-layout-demo-basic .ant-layout-sider {
  color: #fff;
  line-height: 120px;
  /* background: #3ba0e9; */
  margin-bottom: 20px;
  margin-right: 10px;
  border-radius: 4px;
}
.ant-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 32px;
  color: #5d5454;
  font-size: 12px;
  font-weight: 600;
}
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0 0 3px;
  line-height: 1.5715;
  white-space: normal;
  white-space: initial;
  text-align: left;
}
.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum", "tnum", "tnum";
          font-feature-settings: "tnum", "tnum", "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: hsl(0, 7%, 3%);
  font-size: 13px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  font-weight: 300;
}
.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #080707;
  font-size: 13px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum", "tnum", "tnum";
          font-feature-settings: "tnum", "tnum", "tnum";
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-weight: 300;
}
.ant-picker-input > input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: #080707;
  font-size: 13px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  flex: auto;
  min-width: 1px;
  height: auto;
  padding: 0;
  background: transparent;
  border: 0;
  font-weight: 300;
}
/* .ant-collapse > .ant-collapse-item {
  border-top: 0.5px solid #ebeaea !important;
  border
} */
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative !important;
  padding: 5px 17px !important;
  color: rgba(0, 0, 0, 0.85) !important;
  line-height: 1.5715 !important;
  cursor: pointer !important;
  transition: all 0.3s, visibility 0s !important;
  font-weight: 600 !important;
  font-size: 13px !important;
}
.ant-typography.ant-typography-secondary {
  color: #202223;
  font-size: 12px;
  font-weight: normal;
}
.ant-typography {
  color: #212B36;
  overflow-wrap: break-word;
  font-size: 13px;
  font-weight: 200;
}

.ant-checkbox-inner {
  position: relative;
  top: -2.5px;
  left: 0;
  display: block;
  width: 14px;
  height: 14px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-table-small .ant-table-thead > tr > th {
  background-color: #fff;
}
.ant-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #464646;
  font-size: 13px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum", "tnum", "tnum";
          font-feature-settings: "tnum", "tnum", "tnum";
  display: flex;
  overflow: hidden;
  font-weight: 550;
}
.ant-tabs-tab {
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  font-family: "Inter";
} 
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0c4da9;
  text-shadow: 0 0 0.25px currentColor;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  padding: 8px 16px;
  background: #ffffff;
  border: 0px solid #f0f0f0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: #fff;
  background-color: #ffffff;
  border-bottom: 2px solid #1648aa;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 0px;
}
.ant-select-arrow {
  color: black !important;
  position: absolute !important;;
  top: 51% !important;;
  right: 0 !important;;
  margin-top: -2px !important;;
  display: inline-block !important;;
  width: 0 !important;;
  height: 0 !important;;
  margin-right: 10px !important;;
  vertical-align: 26.255em !important;;
  content: "" !important;;
  border-top: 0.3em solid !important;;
  border-right: 0.3em solid transparent !important;;
  border-bottom: 0 !important;;
  border-left: 0.3em solid transparent !important;;
  transition: all 0.2s ease-in-out !important;;
}
.ant-select-arrow svg {
  display: none !important;
}
.ant-btn-primary {
  border-color: 'none' !important;
}
.ant-btn-primary:hover {
   border-color: 'none' !important;
}
.ant-btn:hover{
  border-color: 'none' !important;
  color: "none" !important
}
.ant-result-content {
  background-color: rgb(242, 243, 246);
}
.ant-result {
  padding: 15px 0px 0px 0px !important;
}
.ant-result-title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
  line-height: 1.8;
  text-align: center;
}
.ant-menu-submenu-arrow {
  position: absolute;
  top: 0;
  right: 0px;
  width: 0px;
  color: #666666;
}
.ant-menu-sub.ant-menu-inline {
  padding: 0;
  background: #fff;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fbfbfb;
}
.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 14px;
  font-size: 13px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
  padding-left: 0px;
}
.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  margin-left: -2px;
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
}
/* .ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: rgba(0, 0, 0, 0.85);
  background-color: #fbfbfb;
} */
.ant-menu-submenu:hover,
.ant-menu-submenu-active,
.ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover .ant-menu-submenu-arrow {
  color: rgba(0, 0, 0, 0.85);
}
.ant-menu-item-selected {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 550;
  font-size: 13px;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid rgba(0, 0, 0, 0.85);
  -webkit-transform: scaleY(0.0001);
          transform: scaleY(0.0001);
  opacity: 0;
  transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: "";
}
.ant-menu-submenu-arrow::before,
.ant-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  color: #666666;
  height: 1.5px;
  background-color: currentColor;
  border-radius: 94px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: "";
}
.ant-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 1.5715;
  -webkit-font-feature-settings: "tnum", "tnum", "tnum";
          font-feature-settings: "tnum", "tnum", "tnum";
  margin-bottom: 0;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 13px;
  line-height: 0;
  text-align: left;
  list-style: none;
  background: #fff;
  outline: none;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 0.5px solid #d7dade;
  border-radius: 3px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-selected {
  color: rgba(0, 0, 0, 0.85);
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 40px;
  margin-top: 0px;
  margin-bottom: -4px;
  padding: 0 7px;
  overflow: hidden;
  line-height: 40px;
  text-overflow: ellipsis;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 30px;
  line-height: 30px;
  list-style-position: inside;
  list-style-type: disc;
  margin-left: -48px;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 5px;
}
.ant-divider-horizontal {
  border-top: 2px solid #e5e5ff;
  padding: 3px 2px 2px 0px !important;
}
.ant-modal-footer {
  background-color: #fff !important;
  border-top: 1px solid #ececec !important;
}
.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dddbda;
  border-radius: 2px;
  box-shadow: #707070 0px 0px 3px 0px;
  pointer-events: auto;
}
.ant-modal-body {
  /* padding-bottom: 0px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px; */
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.ant-modal-close-x {
  display: block;
  font-size: 22px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  position: absolute;
  right: -21px;
  bottom: -10px;
  color: white;
}
.ant-modal-header {
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  text-align: center;
  border-radius: 2px 2px 0 0;
  border-bottom: 0px solid #f0f0f0 !important;
}
.ant-modal-title {
  margin: 0;
  color: #3e3e3c;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  pointer-events: none;
  font-weight: 500;
}
.ant-collapse-content {
  color: rgba(0, 0, 0, 0.85);
  background-color: #fff;
  border-top: 0px solid #d9d9d9;
}
/* .ant-table-row-expand-icon {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2.6px;
  height: 0px;
  width: 0px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-left: 20px;
}
.ant-table-row-expand-icon-expanded {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2.6px;
  height: 0px;
  width: 0px;
  margin-left: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
} */
.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 0px;
}
.ant-modal-confirm-body > .anticon {
  /* float: left; */
  margin-right: 16px;
  font-size: 22px;
}
.ant-modal-confirm-body {
  text-align: center;
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  font-weight: 600;
}
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #faad14;
  position: relative;
  left: 120px;
}

.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  opacity: 0.9;
}
.ant-table .ant-table-expanded-row-fixed {
  position: relative;
  margin: -5px -5px;
  padding: 5px 0px;
}

.ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: 2px 6px -8px 10px;
}
.ant-table.ant-table-small .ant-table-expanded-row-fixed {
  margin: -8px -8px;
  background-color: #fff;
}
.ant-alert-close-icon {
  margin-left: 8px;
  padding: 0;
  overflow: hidden;
  font-size: 12px;
  line-height: 12px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 5px;
  margin-right: -7px;
}
.ant-alert-with-description.ant-alert-no-icon {
  padding: 5px 15px 5px 10px;
}
.ant-alert-with-description .ant-alert-message {
  display: block;
  margin-bottom: 0px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 12px;
  font-weight: 600;
}
.ant-alert-description {
  display: none;
  font-size: 12px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
}
.ant-table-cell .ant-table-container:first-child {
  border-top: 0;
  margin-top: 8px;
  margin-bottom: 14px;
}
.ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum", "tnum", "tnum";
          font-feature-settings: "tnum", "tnum", "tnum";
  margin-bottom: -12px;
  vertical-align: top;
}
.ant-dropdown-trigger.ant-table-filter-trigger {
  display: none;
  padding: 4px;
}

.ant-table-cell:hover .ant-dropdown-trigger.ant-table-filter-trigger {
  display: block;
}
.ant-table-row-expand-icon {
  color: #c7c7c7 !important;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  display: inline-flex;
  float: left;
  box-sizing: border-box;
  width: 15px;
  height: 15px;
  padding: 0;
  color: inherit;
  line-height: 17px;
  background: #fff;
  border: 0px solid #f0f0f0;
  border-radius: 2px;
  outline: none;
  -webkit-transform: scale(0.94117647);
          transform: scale(0.94117647);
  transition: all 0.3s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 10px;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: #080707;
}
table tr th.ant-table-selection-column,
table tr td.ant-table-selection-column {
  /*  padding-right: 8px;
  padding-left: 8px; */
  text-align: left;
}
.ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum", "tnum", "tnum";
          font-feature-settings: "tnum", "tnum", "tnum";
  background-color: #ffffff;
  /* border: 0px solid #d9d9d9 !important; */
  /* border-top: 1px solid #d9d9d9 !important; */
  border-bottom: 0;
  border-radius: 2px;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 0px solid #d9d9d9;
  margin-bottom: 2px;
  background-color: #fafafa;
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 0px 0px !important;
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 0px solid #f0f0f0;
  content: "";
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: white;
  border-color: #d9d9d9;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 1.9px solid #666666;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(39deg) scale(1) translate(-50%, -50%);
          transform: rotate(39deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: " ";
  top: 5px;
  left: 2.5px;
  width: 5px;
  height: 9px;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: white;
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: " ";
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #d9d9d9;
}
.ant-checkbox-inner {
  position: relative;
  top: -2.5px;
  left: 0px;
  display: block;
  width: 14px;
  height: 14px;
  direction: ltr;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0px solid #e6f7ff;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
  animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  content: "";
}
.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #e6f7ff;
  border-color: rgba(0, 0, 0, 0.03);
}
.ant-tabs-tabpane {
  flex: none;
  width: 100%;
  outline: none;
  border: 0.25px solid #d7dade;
  border-bottom: none !important;
  border-left: none;
  border-right: none;
  /* padding-top: 5px; */
}
/* .ant-popover-placement-bottom .ant-popover-arrow,
.ant-popover-placement-bottomLeft .ant-popover-arrow,
.ant-popover-placement-bottomRight .ant-popover-arrow {
  top: -4.485281px;
}
.ant-popover-arrow {
  position: absolute;
  display: block;
  width: 20.485281px;
  height: 33.485281px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.ant-popover-arrow-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 20px;
  height: 20px;
  margin: auto;
  background-color: #fff;
  content: "";
  pointer-events: auto;
}
 */
ul {
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 0em !important;
          margin-block-start: 0em !important;
  -webkit-margin-after: 0em !important;
          margin-block-end: 0em !important;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  -webkit-padding-start: 40px;
          padding-inline-start: 40px;
}
.ant-menu.ant-menu-inline-collapsed {
  width: 40px;
  /* margin-left: 8px; */
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon {
  padding-left: 20px;
}
.inner {
  min-width: 20rem;
  max-width: 21rem;
  margin: 0 auto;
  padding: 1.5rem;
  border-radius: 20px;
  overflow: hidden;
  background-color: #f2f3f6 !important;
  position: relative;
}

@media (min-width: 320px) and (max-width: 370px) {
  .mainLayoutPadding{
    padding: 8px 0px 8px 0px;
  }
  .mainLayoutDashboardPadding{
    padding: 8px 10px 8px 10px;
  }

  .auditTrialText {
    font-weight: 600;
    font-family: "Inter";
    font-size: 16px;
  }
  /* .formRecordTitle {
    width: auto;
    max-width: 160px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
  } */
}

@media (min-width: 371px) and (max-width: 410px) {
  .mainLayoutPadding{
    padding: 8px 0px 8px 0px;
  }
  .mainLayoutDashboardPadding{
    padding: 8px 10px 8px 10px;
  }

  .auditTrialText {
    font-weight: 600;
    font-family: "Inter";
    font-size: 16px;
  }
  /* .formRecordTitle {
    width: auto;
    max-width: 190px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
  } */
}
@media (min-width: 411px) and (max-width: 520px) {
  .mainLayoutPadding{
    padding: 8px 0px 8px 0px;
  }
  .mainLayoutDashboardPadding{
    padding: 8px 10px 8px 10px;
  }

  .auditTrialText {
    font-weight: 600;
    font-family: "Inter";
    font-size: 16px;
  }
  /* .formRecordTitle {
    width: auto;
    max-width: 250px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
  } */
}

@media (min-width: 521px) and (max-width: 600px) {
  .mainLayoutPadding{
    padding: 8px 0px 8px 0px;
  }
  .mainLayoutDashboardPadding{
    padding: 8px 10px 8px 10px;
  }

  .auditTrialText {
    font-weight: 600;
    font-family: "Inter";
    font-size: 16px;
  }
  /* .formRecordTitle {
    width: auto;
    max-width: 320px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
  } */
}

@media (min-width: 320px) and (max-width: 600px) {
  .mainLayoutPadding{
    padding: 8px 0px 8px 0px;
  }
  .mainLayoutDashboardPadding{
    padding: 8px 10px 8px 10px;
  }

  .block {
    padding: 35% 2.4% 6.66rem 2% !important;

    width: 100%;
    align-self: center;
    position: relative;
    z-index: 2;
    width: 100%;
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .responsiveSideMenu {
    width: 0px !important;
  }
  .toggleOnMobile {
    background-color: #080707;
    width: 30px;
    height: 30px;
    margin-left: -15px;
    margin-top: 75px;
    position: relative;
    z-index: 10;
  }
  .ant-dropdown-menu {
    position: relative;
    margin: 0;
    padding: 4px 0;
    text-align: auto;
    list-style-type: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    /* text-align: right; */
    padding-top: 8px;
    height: auto;
    padding-left: 8px;
    padding-right: 4px;
    padding-top: 9px;
  }
  .addNewInList {
    height: 2rem;
    width: 5.8rem;
    /* background: rgb(8, 158, 164); */
    border: 0px solid rgb(7, 136, 141);
    border-radius: 2px;
    font-size: 14px;
    color: #161417;
    font-weight: 500;
    font-family: "Open Sans";
    float: right;
    opacity: 1;
    text-align: left;
    box-shadow: 0px 0px 0px gray;
    padding-left: 5px;
  }
}

@media (min-width: 601px) and (max-width: 1004px) {
  .mainLayoutPadding{
    padding: 8px 0px 8px 0px;
  }
  .mainLayoutDashboardPadding{
    padding: 8px 10px 8px 10px;
  }

  .auditTrialText {
    font-weight: 600;
    font-family: "Inter";
    font-size: 20px;
  }
  .block {
    padding: 18% 2.4% 6.66rem 2% !important;

    width: 100%;
    align-self: center;
    position: relative;
    z-index: 2;
    width: 100%;
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .responsiveSideMenu {
    width: 0px !important;
    margin-left: 0px!important;
  }
  .toggleOnMobile {
    background-color: #080707;
    width: 30px;
    height: 30px;
    margin-left: -15px;
    margin-top: 75px;
    position: relative;
    z-index: 10;
  }
  .ant-dropdown-menu {
    position: relative;
    margin: 0;
    padding: 4px 0;
    text-align: auto;
    list-style-type: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    /* text-align: left; */
    padding-top: 8px;
    height: auto;
    padding-left: 8px;
    padding-right: 4px;
    padding-top: 9px;
  }
  .addNewInList {
    height: 2rem;
    width: 5.8rem;
    /* background: rgb(8, 158, 164); */
    border: 0px solid rgb(7, 136, 141);
    border-radius: 2px;
    font-size: 14px;
    color: #161417;
    font-weight: 500;
    font-family: "Open Sans";
    float: right;
    opacity: 1;
    text-align: left;
    box-shadow: 0px 0px 0px gray;
    padding-left: 5px;
  }
  /* .formRecordTitle {
    width: auto;
    max-width: 450px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
  } */
}

@media (min-width: 1005px) {
  .mainLayoutPadding{
    /* padding: 15px 23px 15px 0px; */
    padding-top: 0.7em;
    padding-bottom: 0.2em;
    padding-right: 14px;
  }

   .mainLayoutDashboardPadding{
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 46px;
    padding-left: 23px;
  }

  .auditTrialText {
    font-weight: 600;
    font-family: "Inter";
    font-size: 20px;
  }
  .block {
    padding: 12% 2.4% 6.66rem 2% !important;

    width: 100%;
    align-self: center;
    position: relative;
    z-index: 2;
    width: 100%;
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .responsiveSideMenu {
    width: auto !important;
  }
  .formRecordTitle1 {
    width: auto;
    min-width: 2px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
  }
}
.formRecordTitle {
  width: auto;
  max-width: 70% !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
}
.textinner {
  min-width: 19rem !important;
  max-width: 27.66rem;
  margin-top: 14%;
  margin-left: 3%;
  padding-left: 1%;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  padding: 5px !important;
}
.ant-drawer-body {
  flex-grow: 1;
  padding: 10px;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
  margin-right: -8px;
}
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small tfoot > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
table.ant-table-small .ant-table-footer {
  padding: 7px 5px 7px 13px;
  left: -5px;
}

/* Dileep */
.ant-table-column-sorter.ant-table-column-sorter-full {
  display: none;
}
/* Dileep */
.ant-table-cell:hover .ant-table-column-sorter.ant-table-column-sorter-full {
  display: block;
}
/* Dileep */
.ant-table-column-sorter-up, .ant-table-column-sorter-down {
  font-size: 9px;
}

.ant-menu-submenu-arrow{
  display: none;
}

/* Sirish */

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: transparent;
}

/* Sirish */

.ant-select-selection-item {
  color: #212B36;
    overflow-wrap: break-word;
    font-size: 13px;
    font-weight: 200;
}

/* Sirish */

.ant-input[disabled] {
  background-color: transparent;
  color: #212B36;
    overflow-wrap: break-word;
    font-size: 13px;
    font-weight: 200;
}

/* Sirish */

.ant-picker.ant-picker-disabled {
  background: transparent;
  border: 0.5px solid #d7dade;
}

.ant-picker-input > input[disabled] {
  color: #212B36;
  overflow-wrap: break-word;
  font-size: 13px;
  font-weight: 200;
}

/* Sirish */

.ant-form-item-label > label {
  font-weight: normal;
  color: #202223;
}

/* Sirish */

.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
  padding-bottom: 0px;
}

/* Sirish */

.ant-table-row-expand-icon {
  color: #5a5959 !important;
}

.ant-table-row-indent + .ant-table-row-expand-icon {
  margin-top: 2.5005px !important;
  margin-right: 8px !important;
}

.ant-list-bordered.ant-list-sm .ant-list-header {
  background-color: #F5F5F5;
  border-bottom: 1px solid #d9d9d9;
/* border-radius: 4px 4px 0px 0px; */
}

.ant-table-tbody > tr > td {
  border-bottom: 
1px
solid #E9EDEC !important;
font-family: "Inter" !important;
font-size: 0.8125rem !important; 
font-weight: 400 !important;
}

/* .ant-table-thead > tr > th {
  border-bottom: 
0px
solid #E9EDEC !important;
} */
.tabTable.ant-table.ant-table-small .ant-table-thead > tr > th{
  font-weight: 700 !important;
}
.ant-table-filter-trigger.active{
  display: block;
}
#logo-img1 {
	width: 78%;
	max-width: 95px;
}
.firstMenu.ant-menu-horizontal > .ant-menu-item{
  opacity: 0.7 !important;
  padding: 0 1.5em !important;
}
.firstMenu.ant-menu-horizontal > .ant-menu-item::after,
.firstMenu.ant-menu-horizontal > .ant-menu-item:hover,
.firstMenu.ant-menu-horizontal > .ant-menu-item-selected,
.firstMenu.ant-menu-horizontal > .ant-menu-item-active
/* .firstMenu.ant-menu-horizontal > .ant-menu-item-open { */
 {
    /* border-bottom: 2px solid red;  */
   /* border-bottom: none !important; */
  transition: none !important;
  background-color: transparent;
  color: white !important;
  opacity: 1 !important;
  font-size: 1em !important;
}

.firstMenu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.firstMenu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.firstMenu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.firstMenu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.firstMenu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.firstMenu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.firstMenu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.firstMenu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  /* border-bottom: 2px solid white; */

   /* content:'▼'; 
    height:13px; 
   width:13px; 
   border: 6px solid #0C173A ;  */
   display: block;
   border-bottom: #FFFFFF;
   left:39%;  
   margin-top: 5px;
   height:8px;
   width:19px;
   background-image: url(/static/media/TailIcon.311b88f3.svg);  
} 

.ant-menu{
	box-shadow: none;
}
.secondMenu.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-item-selected::after,
.secondMenu.ant-menu-horizontal>.ant-menu-item:hover

{
	color: #192228 !important;
	transition: none !important;
  font-weight: 550 !important;
  font-size: 1em !important;

}
.secondMenu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #A4CD38;
  /* width: 79% !important;
  margin-left: -10px; */
}


.ant-menu-horizontal{
  border-bottom: none !important;
}
.ant-popover-arrow {
  display: none !important;
}
.logout>.ant-popover-content {
  margin-right: 1vw !important;
  margin-top: -3vh !important;
}
.global-sel>.ant-select-selector{
  height: 4.7vh !important;
}
.global-sel>.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 11px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #2F3856 !important;
  box-shadow: none !important;
  border-right-width: 1px !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #2F3856 !important;
  border-right-width: 1px !important;
}
.fullmenu-active {
    flex: 0 0 !important;
    max-width: 0px !important;
    min-width: 0px !important;
    width: 0px !important;
}
.menu-toggle {
    position: fixed;
    left:12.3rem;
    top: 50%;
    background: transparent;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 10; /* bring the toggle button above the menu */
  }
  .toggle {
    position: fixed;
    left:0%;
    top: 50%;
    background: transparent;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 10; /* bring the toggle button above the menu */
  }
  .menu-toggle2 {
    position: fixed;
    left: 0%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 10; /* bring the toggle button above the menu */  }

  .menu-toggle3 {
    position: fixed;
    left: 0%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 10; /* bring the toggle button above the menu */
  }
/* .fullmenu-inactive {
    flex: 0 0 80px !important;
    max-width: 80px !important;
    min-width: 80px !important;
    width: 80px !important;
} */

.fullmenu-inactive {
    flex: 0 0 60px !important;
    max-width: 60px !important;
    min-width: 60px !important;
    width:60px !important;
}

.cardPadding{
    padding-left: 0px !important;
}
/* .sideMenu.ant-menu-horizontal > .ant-menu-item-selected{
background-color: #4B5770;
} */
.ant-menu-inline.ant-menu-item::after{
    /* border-left: 3px solid #A4CD38 !important;; */
}
.sideMenu.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected
 {
    /* background-color: #4B5770; */
    /* border-radius: 0px !important; */
    /* border-bottom-right-radius:4px ; */
    /* color: #FFFFFF; */
    /* opacity: 1; */
    /* border-left: 3px solid #A4CD38 !important; */
    font-weight: 700 !important;
    font-size: 1em !important;
}
.ant-menu-light.ant-menu-item:hover,.ant-menu-light.ant-menu-item-active{
    color: #FFFFFF;
}
.ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover{
	color:#4B5770 ;
} 
/* .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover{
    color: #000;
} */

.sideMenu.ant-menu:not(.ant-menu-horizontal) .ant-menu-title-content{
    margin-left: 1em !important;
}

.font-footer{
    font-family: 'Oxygen', sans-serif;
}
.mainLayoutPadding1 {
    padding-top: 0.7em;
    padding-bottom: 15px;
    padding-right: 0.9em;
    padding-left: 0.9em;
}
.mainLayoutDashboardPadding1 {
    padding-top: 0px;
    padding-bottom: 15px;
    padding-right: 23px;
    padding-left: 23px; 
}
.mainLayoutReportPadding {
    padding-right: 23px;
    padding-left: 23px; 
}
.mainLayoutReportPadding1 {
    padding-top: 15px;
    padding-right: 13px;
    padding-left: 0px; 
}
.ant-layout {
    background: #f3f4f9;
}
.mainDiv
{background-color:#0C173A; 
  height:100%;
  display:flex;
flex-direction: column;
 justify-content: center; 
align-items: center;

}

body{
  background-color:#0C173A
}

.innerBlock{
    margin: auto;
padding:0.8rem;
    background-color: white;
    width:24.5rem;
    height:20.50%;
    border-radius: 0.5em;
    margin-top: 5%;
    
}

.cwLoginLogo{
    width:12.5em;
    height:2.5em;
     margin-top:5%
}

.resetPasswordLog{
  display: flex;
  flex-direction: row;
  margin-top: 6%;
}

.copyright
{ 
 width:30%;
 margin-top: 3.5%;
}

.resetEmail.ant-input::-webkit-input-placeholder{
    color:#848484;
    font-weight: 400;
    font-size: 1rem;
  }

.resetEmail.ant-input:-ms-input-placeholder{
    color:#848484;
    font-weight: 400;
    font-size: 1rem;
  }

.resetEmail.ant-input::placeholder{
    color:#848484;
    font-weight: 400;
    font-size: 1rem;
  }

  .usernameInput.ant-input::-webkit-input-placeholder{
    color:#0C173A;
    font-weight: 400;
    font-size: 1rem;
  }

  .usernameInput.ant-input:-ms-input-placeholder{
    color:#0C173A;
    font-weight: 400;
    font-size: 1rem;
  }

  .usernameInput.ant-input::placeholder{
    color:#0C173A;
    font-weight: 400;
    font-size: 1rem;
  }

  .passwordColorBackground.ant-input::-webkit-input-placeholder{
    color:#0C173A;
    font-weight: 400;
    font-size: 1rem;
  }

  .passwordColorBackground.ant-input:-ms-input-placeholder{
    color:#0C173A;
    font-weight: 400;
    font-size: 1rem;
  }

  .passwordColorBackground.ant-input::placeholder{
    color:#0C173A;
    font-weight: 400;
    font-size: 1rem;
  }
 

  .passwordColorBackground input {
    background:#D8DADE;
    font-size: 1rem;
  }

  .resetEmail {
    font-weight: 400;
  }

  .resetLoginPassword{
    background-color:#0C173A;
  }



/*   
  .inputEmail{
    background-color: blue;
    height:10vh
  } */


  @media only screen and (min-device-width: 350px) and (max-device-width: 500px) {

  #resetEmailId{
    height:3rem !important;
  }

  .mainReset{
  margin-top: 2rem !important;
  }

  .resetDiv{
    height:28rem !important;
    justify-content: center !important;
    justify-items: center !important;
     position: absolute; 
    left: 50%;
    top: 40%; 
     -webkit-transform: translate(-50%, -50%); 
             transform: translate(-50%, -50%);
  }

  .resetBut{
    height:3rem !important;

  }
  .link{
    margin-top: 2rem !important;
  }
 

/* For MobilePhones: */
.col-ms-1 {width: 8.33%;}
.col-ms-2 {width: 16.66%;}
.col-ms-3 {width: 25%;}
.col-ms-4 {width: 33.33%;}
.col-ms-5 {width: 41.66%;}
.col-ms-6 {width: 50%;}
.col-ms-7 {width: 58.33%;}
.col-ms-8 {width: 66.66%;}
.col-ms-9 {width: 75%;}
.col-ms-10 {width: 83.33%;}
.col-ms-11 {width: 91.66%;}
.col-ms-12 {width: 100%;}
}


@media only screen and (min-device-width: 1000px) and (max-device-width: 1600px) {




  /* For MobilePhones: */
.col-tv-1 {width: 8.33%;}
.col-tv-2 {width: 16.66%;}
.col-tv-3 {width: 25%;}
.col-tv-4 {width: 33.33%;}
.col-tv-5 {width: 41.66%;}
.col-tv-6 {width: 50%;}
.col-tv-7 {width: 58.33%;}
.col-tv-8 {width: 66.66%;}
.col-tv-9 {width: 75%;}
.col-tv-10 {width: 83.33%;}
.col-tv-11 {width: 91.66%;}
.col-tv-12 {width: 100%;}
}
.tabButton.ant-btn:not([disabled]):hover {
    background-color: #C9C9C9 !important ;
    }

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        border-radius: 5px !important;
    }
  .ant-picker {
        border-radius: 5px !important;
        height: 1.8rem !important;
    }
    
.row-light {
    background-color: #f5b6b6;
  }
  
  .row-dark {
    background-color: #84eb84;
  }
  .ant-table-cell-hover {
    background-color: none !important;
  }
/* .searchInput.ant-input:placeholder-shown{
    font: normal normal normal 14px/19px Open Sans;
    color: #858282;
} */
.ant-table-column-title{
  font-family:'Inter';
  font-weight:700;
  font-style: normal;
  font-size: 0.75rem !important;
  margin-top: 1px !important;
}
.ant-input:-ms-input-placeholder {
  text-overflow: ellipsis;
  font-family: "Inter";
  color: #0C173A !important;
  background: transparent;
  line-height: 17px;
}
.ant-input:placeholder-shown {
  text-overflow: ellipsis;
  font-family: "Inter";
  color: #0C173A !important;
  background: transparent;
  line-height: 17px;
}
table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
padding: 7px 5px 7px 5px;
left: -5px;
background-color: white !important;
}
.ant-table-thead .ant-table-cell {
  background-color: white;
}
.ant-table.ant-table-small {
  font-size: 13px;
}
.ant-btn:hover{
color: #192228;
}

/* .ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
margin: -8px -8px -8px 0px;
} */
/* .ant-checkbox-inner{
border: 1.5px solid rgba(12, 23, 58, 0.5) !important;
} */
.ant-checkbox-checked::after {
border: none !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
background-color: #0c173a !important;
border-color: #0c173a !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
border-color: #0c173a !important;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
background-color: #0c173a !important;
}
.ant-checkbox-inner{
border-radius: "3px" !important;
}
.ant-checkbox-checked .ant-checkbox-inner::after{
top:"6px" !important;
}
.listHeaderButtons.ant-btn:not([disabled]):hover {
background-color: #C9C9C9 !important ;
}
.ant-tooltip-inner {
font-family: 'Inter' !important;
font-style: normal !important;
font-weight: 500 !important;
font-size: 14px !important;
color: #FFFFFF !important;
background: rgba(12, 23, 58, 0.45) !important;
border-radius: 2px !important;
max-height: 25px !important;
min-height: -webkit-fit-content;
min-height: -moz-fit-content;
min-height: fit-content;
padding: 1px 5px !important; 
}
.ant-tooltip-arrow-content {
background: rgba(12, 23, 58, 0.45) !important;
}
/* .ant-tooltip-placement-top{
top:97px !important
}  */
.show {
opacity: 1;
transition: opacity 0.5s ease-in-out;
}
.search-input {
opacity: 0;
-webkit-transform: translateX(-7%);
        transform: translateX(-7%);
transition: opacity 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}

.search-input.show {
opacity: 1;
-webkit-transform: translateX(0%);
        transform: translateX(0%);
}
.listSearch.ant-input:hover{
border: 0.5px solid #a5a5a5 !important;
/* border-right: 3px solid #a5a5a5!important; */
} 
.ant-message-custom-content{
  display: flex !important;
}
.ant-message .anticon {
  top: 7px !important;
}
.ant-input-affix-wrapper:focus{
  border-color: #D1D1D1 !important;
  box-shadow: none !important;
}
/* .listSearch>.ant-input{
  background: #f3f4f9 !important;
} */
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #6277b8 !important;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus{
  border-color: #2F3856 !important;
}
.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  border-color: #2F3856 !important;
  box-shadow: none !important;
  border-right-width: 1px !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #192228 !important;
    font-weight: 550;
    font-family: "Inter";
    font-size: 12px;
}
/* .ant-collapse-item {
    border: none !important;
    border-bottom: 1px solid #d9d9d9 !important;
}
.ant-collapse-item.ant-collapse-item-active{
    border-bottom: none !important;
} */
.dropMenu.ant-menu-vertical{
    border-right: none;
}
.ant-tabs-nav{
    padding-left: 1.7em;
}
.ant-collapse{
    border: 1px solid #b0b0b00d !important;
}
.ant-collapse > .ant-collapse-item {
    border-bottom: none !important;
}
.ant-collapse-content{
    border-top: none !important;
}
.ant-menu-title-content{
font-family: "Inter" !important;
}
.listHeaderButtons.ant-btn:not([disabled]):hover {
    background-color: #C9C9C9 !important ;
  }
  .actionsModal .ant-modal-content {
    border-radius: 8px !important;
  }
  .actionsModal .ant-modal-header {
    border-radius: 8px !important;
  }
  .recordCard>.ant-card-body {
    padding: 0px !important;
    padding-top: 0.5em !important;
  }
.chartTitle:hover .maxIcon{
  opacity: 1 !important;
}

.maxIcon {
opacity: 1 !important;
float: right;
}

rect.disabled {
  fill: transparent !important;
}

.badgemainamount {
  font-size: 20px;
  color:'#19181A';
  opacity: '0.8';
  font-weight: bold;
}

div.ant-modal-header-custom > div.ant-modal-content {
  border-radius: 6px !important;
}
.op {
  position: relative;
  height:90px;
  width:100%;
}
.ant-skeleton-element {
  display: inline-block;
  width: 100%;
}
.c3-ygrid-line.grid8 line {
    stroke: #0C173A;
    stroke-opacity: 0.05;
    /* margin-left: 200; */
}
.c3-legend-background{
    stroke: none !important;
}

html,body{
    height:100%;
}

#wrapper{
    position:relative;
    /*background:#333;*/
    height:100%;
}

.profile-main-loader{
    left: 50% !important;
    margin-left:-100px;
    position: fixed !important;
    top: 50% !important;
    margin-top: -100px;
    width: 45px;
    z-index: 9000 !important;
}

.profile-main-loader .loader {
  position: relative;
  margin: 0px auto;
  width: 200px;
  height:200px;
}
.profile-main-loader .loader:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.circular-loader {
  -webkit-animation: rotate 2s linear infinite;
          animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
}

.loader-path {
  stroke-dasharray: 150,200;
  stroke-dashoffset: -10;
  -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
          animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}
@-webkit-keyframes color {
  0% {
    stroke: #1b55e3;
  }
  40% {
    stroke: #1b55e3;
  }
  66% {
    stroke: #1b55e3;
  }
  80%, 90% {
    stroke: #1b55e3;
  }
}
@keyframes color {
  0% {
    stroke: #1b55e3;
  }
  40% {
    stroke: #1b55e3;
  }
  66% {
    stroke: #1b55e3;
  }
  80%, 90% {
    stroke: #1b55e3;
  }
}


/* .ant-modal-header-custom{
    padding-bottom: 0px !important;
} */

.ant-table-summary > tr > td {
    border-bottom: none !important;
}
.reportCol.ant-col.ant-col-24{
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 8px;
}

/* div.ant-modal-header-custom > div.ant-modal-content > div.ant-modal-header {
    padding-bottom: 0px !important;
    padding-top: 15px !important;
    background-color: #F2F3F6 !important;
} */
.tagReport.ant-tag {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum", "tnum";
            font-feature-settings: 'tnum', "tnum", "tnum";
    display: inline-block;
    height: 30px;
    margin-right: 8px;
    padding: 0 7px;
    font-size: 12px;
    line-height: 28px;
    white-space: nowrap;
    background: white;
    border: 1px solid #D3D3D3;
    border-radius: 2px;
    opacity: 1;
    transition: all 0.3s;
}


.table-responsive {
  overflow-y: scroll;
  height: 73vh;
  font-size: 11px;
}

.table {
  width: 1000%;
  max-width: 100%;
  margin-bottom: 1rem;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #eceeef;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #eceeef;
}

.table tbody + tbody {
  border-top: 2px solid #eceeef;
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #eceeef;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #eceeef;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #dff0d8;
}

.table-hover .table-success:hover {
  background-color: #d0e9c6;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #d0e9c6;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d9edf7;
}

.table-hover .table-info:hover {
  background-color: #c4e3f3;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #c4e3f3;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fcf8e3;
}

.table-hover .table-warning:hover {
  background-color: #faf2cc;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #faf2cc;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f2dede;
}

.table-hover .table-danger:hover {
  background-color: #ebcccc;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #ebcccc;
}

.thead-inverse th {
  color: #fff;
  background-color: #292b2c;
}

.thead-default th {
  color: #464a4c;
  background-color: #eceeef;
}

.table-inverse {
  color: #fff;
  background-color: #292b2c;
}

.table-inverse th,
.table-inverse td,
.table-inverse thead th {
  border-color: #fff;
}

.table-inverse.table-bordered {
  border: 0;
}

.filter-Pivot {
  border: 0px solid gray !important;
}

.ant-select-selector1 {
  border: 0px solid gray !important;
}

.testingStyle {
  margin-bottom: -10px !important;
}

.pivotRows div {
  border: none !important;
  font-size: 12px;
}

.pivot-right {
  text-align: right; 
}

.pivot-left {
  text-align: left; 
}

.table-row-light {
    background-color: #ffffff;
}
.table-row-dark {
    background-color: #E6E6FA;
    color:#080707;    
}


.table-row-light {
    background-color: #ffffff;
}
.table-row-dark {
    background-color: #E6E6FA;
    color:#080707;    
}


/* body {
  background-color: #f2f3f6;
} */

.alerts-tabs-div {
  padding: 10px;
  background-color: #f5f5f5;
  cursor: pointer;
}

.alerts-tab-div-active {
  padding: 10px;
  background-color: #fff;
  cursor: pointer;
}

.alerts-tabs-active {
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 600;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #1648aa;
  text-align: center;
}

.alerts-tabs {
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 600;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #464646;
  text-align: center;
}

.searchInput,
.searchInput:hover,
.searchInput:focus {
  border: 0.5px solid #d7dade !important;
  border-radius: 3px;
  box-shadow: none !important;
}

.searchInput::-webkit-input-placeholder {
  font-size: 14px;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #0c090a;
  opacity: 0.42;
}

.searchInput:-ms-input-placeholder {
  font-size: 14px;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #0c090a;
  opacity: 0.42;
}

.searchInput::placeholder {
  font-size: 14px;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #0c090a;
  opacity: 0.42;
}

.selectedItem {
  margin-right: 9px;
  margin-top: 3px;
  padding: 19px 0px;
  background-color: #07888d;
}

.alertName {
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 600;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #000000;
}

.alertLoc {
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: normal;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #464646;
}

.selectedPerson {
  margin-bottom: 0px;
  font-size: 18px;
  font-family: "Open sans";
  letter-spacing: -0.5px;
  color: #161537;
  opacity: 1;
}

.status {
  margin-bottom: 0px;
  font-size: 12px;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #080707;
  opacity: 1;
}

.personStatus {
  margin-bottom: 20px;
  font-size: 12px;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #080707;
  opacity: 1;
  text-align: center;
}

.alertTitle {
  margin-bottom: 0px;
  font-size: 18px;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #161537;
  opacity: 1;
}

.this-is-to {
  margin-bottom: 25px;
  margin-top: 20px;
  font-size: 13px;
  font-family: "Open sans";
  letter-spacing: 0.45px;
  color: #0f1014;
  opacity: 1;
}

.details-title {
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 600;
  font-family: "Open sans";
  letter-spacing: 0.45px;
  color: #0f1014;
  opacity: 1;
}

.details-value {
  font-size: 13px;
  font-weight: normal;
  font-family: "Open sans";
  letter-spacing: 0.45px;
  color: #0f1014;
  opacity: 1;
}

.typing-input,
.typing-input:hover,
.typing-input:focus {
  padding: 10px;
  border: 1px solid #00000021 !important;
  border-radius: 3px;
  box-shadow: none !important;
  opacity: 1;
}

.new-person-title {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 600;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.mail-address {
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: normal;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #464646;
}

.newRequest {
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: normal;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #1648aa;
}

.accept-request-div {
  margin: 0px -10px 15px;
  padding: 10px 20px;
  background-color: #089ea43b;
  border: 0.5px solid #bcdedf;
  border-radius: 4px;
}

.request-person-msg {
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: normal;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #464646;
}

.ignore-btn,
.ignore-btn:hover,
.ignore-btn:focus {
  background-color: transparent;
  border: none;
  font-size: 10px;
  font-weight: 600;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #464646;
  box-shadow: none !important;
  outline: none;
}

.accept-btn,
.accept-btn:hover,
.accept-btn:focus {
  background-color: #089ea4;
  border: 0.5px solid #07888d !important;
  border-radius: 2px;
  font-size: 10px;
  font-weight: 600;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #ffffff;
  box-shadow: none !important;
  outline: none;
}


.custom .ant-collapse-content-box {
    padding: 0;
  }

  .headerPadding .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding-left: 4px;
  }
.custom .ant-collapse-content-box {
    padding: 0;
  }

  .headerPadding .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding-left: 4px;
  }
.pref-headings-div{
    background-color: #E4E5E7;
    height: auto;
    padding: 0.3rem 1.5rem;
    align-items: center;
   
}
.pref-headings{
    font-size: 16px;
    font-weight: 500;
    font-family: Inter;
    font-style: normal;
    line-height: 19px;
}
.pref-card-div{
    display: flex;
    padding: 0.3rem 1.5rem ;
    align-items: center;
    
}
.inside-text{
    font-size: 12px !important;
    /* line-height: 15px !important; */
    color: #0C173A !important;
    font-weight: 400 !important;
    font-family: Inter !important;
    font-style: normal !important;

}
.inside-text.ant-select-selection-item{
    font-size: 12px;
    line-height: 15px;
    color: #0C173A ;
    font-weight: 400;
    font-family: Inter;
    font-style: normal;

}
/* .ant-input{
    font-size: 12px;
    line-height: 15px;
    color: #0C173A !important;
    font-weight: 400;
    font-family: Inter;
    font-style: normal;
} */
.left-border{
    border-left: 1px solid #D3D3D3 ;
    height: auto;
}
.left-border-customer1{
    border-left: 1px solid #D3D3D3 ;
    height: auto;
}
.left-border-customer{
    border-left: 1px solid #D3D3D3 ;
    height: auto;
}
.lable-text{
    font-size: 12px;
    line-height: 15px;
    color: #0C173A;
    font-weight: 400;
    font-family: Inter;
    font-style: normal;
}
.note-text{
    color: #000000;
    opacity: 0.5;
    font-size: 12px;
    font-family: Inter;

}

.centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.main-row{
    padding: 0 6rem;
   
}
@media only screen and (min-width: 993px) {
    .main-row{
        padding: 0 14rem;
       
    }
}
.main-heading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #192228;
    padding: 1rem 0;
}
.container {
    position: relative;
    background: url(/static/media/roller.876de232.png) no-repeat;
    background-size: cover;
    background-position: left top !important; /* Aligns the image to the top-left corner of the div */
    height: 20em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1em;
  }

.left-div {
    background: #0C173A;
    border: 1px solid #E4E7EC;
    box-shadow: 0px 4px 4px #EBEBEB;
    border-radius: 10px 0px 0px 10px;
    padding: 3rem;

}

.leftSide-line-div {
    background: linear-gradient(180deg, #313332 0%, #3D3D3D 26.04%, #3D3D3D 49.48%, #3D3D3D 72.92%, #363636 100%);
    border-radius: 100px;
    -webkit-transform: matrix(1, 0, 0, -1, 0, 0);
            transform: matrix(1, 0, 0, -1, 0, 0);
    margin: 20vh auto;
    width: 50%;
}

.leftSide-line-color {
    background: linear-gradient(90deg, #3DF79D 0%, #CFF226 100%);
    border-radius: 100px 3px 3px 100px;
    -webkit-transform: matrix(1, 0, 0, -1, 0, 0);
            transform: matrix(1, 0, 0, -1, 0, 0);
    height: 1vh;
    align-self: unset;
    width: 50%;

}

.right-divs {
    background: #FFFFFF;
    border: 1px solid #E4E7EC;
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.11);
    margin-bottom: 2rem;
    padding: 1rem;
    border-radius: 4px;
    cursor: pointer;
    /* height: 33%; */
}

.right-div {
    background: #D9D9D9;
    border: 1px solid #E4E7EC;
    box-shadow: 0px 4px 4px #EBEBEB;
    border-radius: 0px 10px 10px 0px;
    padding: 2rem 1rem;
}

.left-sec-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px !important;
    color: #FFFFFF;

}

.right-sec-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #192228;
}

.right-img-div {
    background: #D9D9D9;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    padding: 1rem;
    width: 7vw;
    height: 14vh;
}
.save-button{
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 24px;

color: #192228;
background: #0C173A;
box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
border-radius: 4px;
}
.lable-text{
font-family: Inter;
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 15px;
color: #000000;

}
.input-inside-text{
    font-family: Inter !important;
font-style: normal !important;
font-weight: 400 !important;
font-size: 14px !important;
line-height: 15px !important;
color: #0C173A !important;
width: 60%;
height: 4.5vh;
border-radius: 3px !important;

}
.getStarted.ant-select-selection-item{
    font-size: 14px !important;
}
.ant-collapse-header, .ant-collapse-content-box {
    border: none !important;
  }
  .manage-store-card{
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
  }
  .tax-address-text{
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;    
    color: #0C173A;
    
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 10px 16px;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #192228 !important;
    font-weight: 550;
    font-family: "Inter";
    font-size: 12px;
}
/* .ant-collapse-item {
    border: none !important;
    border-bottom: 1px solid #d9d9d9 !important;
}
.ant-collapse-item.ant-collapse-item-active{
    border-bottom: none !important;
} */
.dropMenu.ant-menu-vertical{
    border-right: none;
}
.ant-tabs-nav{
    padding-left: 1.7em;
}
/* .ant-collapse{
    border: none !important;
} */
.ant-collapse > .ant-collapse-item {
    border-bottom: none !important;
}
.ant-collapse-content{
    border-top: none !important;
}
.ant-menu-title-content{
font-family: "Inter" !important;
}
.listHeaderButtons.ant-btn:not([disabled]):hover {
    background-color: #C9C9C9 !important ;
  }
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #192228 !important;
    font-weight: 550;
    font-family: "Inter";
    font-size: 12px;
}
/* .ant-collapse-item {
    border: none !important;
    border-bottom: 1px solid #d9d9d9 !important;
}
.ant-collapse-item.ant-collapse-item-active{
    border-bottom: none !important;
} */
.dropMenu.ant-menu-vertical{
    border-right: none;
}
.ant-tabs-nav{
    padding-left: 1.7em;
}
/* .ant-collapse{
    border: none !important;
} */
.ant-collapse > .ant-collapse-item {
    border-bottom: none !important;
}
.ant-collapse-content{
    border-top: none !important;
}
.ant-menu-title-content{
font-family: "Inter" !important;
}
.listHeaderButtons.ant-btn:not([disabled]):hover {
    background-color: #C9C9C9 !important ;
  }
.ant-tabs-nav {
    padding-left: 0;
}
.ant-tabs-tabpane {
    flex: none;
    width: 100%;
    outline: none;
    border: 0.25px solid #fff;
    border-bottom: none !important;
    border-left: none;
    border-right: none;
    /* padding-top: 5px; */
}
.ant-table-thead > tr > th {
    color: #0c173a;
    background: #FFFFFF;
    border-bottom: 1px solid #f0f0f0;
    font-size: 12px !important;
    font-weight: 500 !important;
}
